<template>
  <div class="choosePermission">
    <div>
      <h3>กรุณาเลือกสิทธิ์เข้าใช้</h3>
      <div class="option">
        <div data-tag="selectSeller" @click="setPermission('seller')">
          ผู้ขาย
        </div>
        <div data-tag="selectBuyer" @click="setPermission('buyer')">
          ผู้ซื้อ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: { preUrl: { default: "/", type: String } },
  data() {
    return {
      targetPath: "/",
    };
  },
  methods: {
    setPermission(type) {
      if (type === "seller") {
        window.$permission.isBuyer = false;
        window.$permission.isSeller = true;
        window.$permission.type = "seller";
      }

      if (type === "buyer") {
        window.$permission.isBuyer = true;
        window.$permission.isSeller = false;
        window.$permission.type = "buyer";
      }

      console.log("set permission", window.$permission);
      this.$router.replace(this.targetPath);
    },
  },
  mounted() {
    // get target path
    this.targetPath = this.$route.query.targetPath.startsWith(
      "/choosePermission"
    )
      ? "/"
      : this.$route.query.targetPath;

    // get params from the target path
    const targetPathParams = new URLSearchParams(this.targetPath.split("?")[1]);

    console.log("targetPathParams: " + targetPathParams);

    // get the permission from query parameters
    const permission = targetPathParams.get("permission");

    // check for auto set permission
    if (permission) {
      this.setPermission(permission);
    }
  },
};
</script>
<style lang="scss" scoped>
.choosePermission {
  text-align: center;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  > div {
    width: 100%;
  }
}

h3 {
  margin-bottom: 30px;
}

.option {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  > div {
    border: 1px solid rgb(190, 190, 190);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
  }
}
</style>
